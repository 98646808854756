'use client';

import { useClientCache, useClientConfig } from '@spikemark/core';
import { useCallback, useEffect, useState } from 'react';
import { useDeepMemo } from '../use-deep-memo';
import { useRemoteOperationWithKey } from './remote-operation-broker';

export type UseRemoteHookResult<T> = {
  data: T | null;
  error: Error | null;
  isFetching: boolean;
  // Loading is the first fetch, fetching is first and subsequent fetches
  isLoading: boolean;
  refetch: () => void;
};

export type UseRemoteFetchOptions = {
  reset?: boolean;
};

export type UseRemoteOptions<T, TParams> = UseRemoteFetchOptions & {
  key?: string;
  skip?: boolean;
  params?: TParams;
  bypassCache?: boolean;
  onRequest?: (params?: TParams) => void;
  onComplete?: (data: T | null) => void;
  onError?: (error: Error) => void;
};

export function useRemote<T, TParams = any>(
  fetcher?: (params: TParams) => Promise<T | null>,
  options?: UseRemoteOptions<T, TParams>
): UseRemoteHookResult<T> {
  options = useDeepMemo(options);

  const { appFocusHandler } = useClientConfig();
  const cache = useClientCache();
  const [data, setData] = useState<T | null>(() =>
    options?.bypassCache ? null : options?.key ? cache?.get(options.key) : null
  );
  const [error, setError] = useState<Error | null>(null);
  const [isFetching, setIsFetching] = useState<boolean>(!options?.skip);
  const [fetchCount, setFetchCount] = useState<number>(data ? 1 : 0);
  const fetch = useCallback(
    ({ reset = options?.reset ?? true }: UseRemoteFetchOptions = {}) => {
      if (options?.skip || !fetcher) {
        return Promise.resolve(null);
      }

      options?.onRequest?.(options?.params);
      setIsFetching(true);
      setError(null);

      if (reset) {
        setData(null);
      }

      return fetcher(options?.params as TParams)
        .then((result) => {
          setData(result);

          if (cache && options?.key) {
            cache.set(options.key, result);
          }

          options?.onComplete?.(result);
          return result;
        })
        .catch((e) => {
          setError(e);

          if (cache && options?.key) {
            cache.delete(options.key);
          }

          options?.onError?.(e as Error);
          return e;
        })
        .finally(() => {
          setFetchCount((prev) => prev + 1);
          setIsFetching(false);
        });
    },
    [fetcher, options?.skip, options?.params, options?.key]
  );
  const refetch = useCallback(() => {
    fetch({ reset: false });
  }, [fetch]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffect(() => appFocusHandler?.(() => refetch()), [refetch, appFocusHandler]);

  useRemoteOperationWithKey(options?.key, refetch);

  return {
    data,
    error,
    isFetching,
    isLoading: isFetching && fetchCount === 0,
    refetch,
  };
}
